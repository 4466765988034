<template>
    <!-- 新增客户 -->
    <div class="box">
        <loading-new :loading="loading"></loading-new>
        <div class="screenSearchPop" style="bottom:0">
            <div v-if="!loading" class="addPeople">
                <control-vue v-if="editSet.length > 0" ref="controlVue1" :editSet="editSet" :optCode="optCode" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :AONIcustSort="AONIcustSort" :currency="currency"></control-vue>
                <div class="listBox" v-if="editSet1.length!=0">
                    <!-- 联系人 -->
                    {{$t('mx_mobile.Client.1585278139730')}}
                </div>
                <control-vue v-if="editSet1.length > 0" ref="controlVue2" :editSet="editSet1" :optCode="optCode" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue"></control-vue>
            </div>
        </div>
    </div>
</template>
<script>
import controlVue from '../vue/index.vue'
import loadingNew from '../../LoadingVue/index.vue'
import { mapMutations } from 'vuex'
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    name: 'view1',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            addPeopleFrom: {
            },
            editSet: [],
            editSet1: [],
            loading: true,
            optName: this.$t('mx_mobile.workbench.1584939336363'), // '新增'
            pageUrl: ''
        }
    },
    created() {
    },
    mounted() {
        this.unit()
    },
    computed: {
    },
    methods: {
        ...mapMutations({
            set_closeYes: 'SET_CLOSEYES'
        }),
        update() {
            this.editSet = []
            this.editSet1 = []
            this.unit()
        },
        unit() {
            this.pageUrl = this.$route.path
            this.loading = true
            if (this.moduleCode == 'BF001') {
                this.getEditSet()
            } else {
                this.getEditSetOther()
            }
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763'))// 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        getEditSet() { // 获取数据
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF001',
                        type: 'addEditSet'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(_this.swidchEditSet(res.data.data))
                    } else {
                        _this.loading = false
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.loading = false
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            var p2 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF003',
                        type: 'addEditSet'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        let list = []
                        res.data.data.forEach(element => {
                            if (element.fieldName != 'custId') {
                                list.push(element)
                            }
                        })
                        resolve(_this.swidchEditSet(list))
                    } else {
                        _this.loading = false
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.loading = false
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2]).then(function (results) {
                _this.editSet = results[0]
                _this.editSet1 = results[1]
                _this.loading = false
                let addObj = _this.Global.utils.getItem('addObjBF001')
                if (addObj) {
                    setTimeout(() => {
                        _this.$refs.controlVue1.update(addObj)
                        _this.Global.utils.removeItem('addObjBF001')
                    }, 20)
                }
                let addObj2 = _this.Global.utils.getItem('addObjBF003')
                if (addObj2) {
                    setTimeout(() => {
                        _this.$refs.controlVue2.update(addObj2)
                        _this.Global.utils.removeItem('addObjBF003')
                    }, 20)
                }
            })
        },
        getEditSetOther() { // 获取数据
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: _this.moduleCode,
                        type: 'addEditSet'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(_this.swidchEditSet(res.data.data))
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            Promise.all([p1]).then(function (results) {
                _this.editSet = results[0]
                _this.editSet1 = []
                _this.loading = false
                let addObj = _this.Global.utils.getItem('addObj' + _this.moduleCode)
                if (addObj) {
                    let addObj1 = addObj
                    setTimeout(function () {
                        _this.$refs.controlVue1.update(addObj1)
                        _this.Global.utils.removeItem('addObj' + _this.moduleCode)
                    }, 20)
                }
            })
        },
        swidchEditSets(list) {
            let newLList = []
            list.forEach((element) => {
                if (element.editState == '0' || element.editState == '2') {
                    element.disabled = true
                }
                newLList.push(element)
            })
            return newLList
        },
        swidchEditSet(list) {
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                element.controlData = ''
                if (element.editState == '0' || element.editState == '2') {
                    element.disabled = true
                }
                element.fieldValue = element.inDefaultValue
                if (element.fieldGroup == 20) {
                    element.fieldGroup = 0 // 拆分组
                }
                if (element.fieldGroup !== 0) { // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldGroup: element.fieldGroup,
                            cnFieldCaption: '',
                            isTop: element.isTop,
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach(function (them) {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        submitAddFrom() { // 提交新增客户数据
            let data1
            let data2
            if (this.$refs.controlVue1) {
                data1 = this.$refs.controlVue1.submit()
            }
            if (this.$refs.controlVue2) {
                data2 = this.$refs.controlVue2.submit()
            }
            if (this.moduleCode == 'BF001') {
                if (data1 && data2) {
                    this.submitAdd(this.checkBe(data1), this.checkBe(data2))
                }
            } else {
                if (data1) {
                    this.submitAdd(this.checkBe(data1))
                }
            }
        },
        submitAdd(data1, data2) {
            console.log('submitAdd')
            let _this = this
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.getFormKey((formKey, msg) => {
                if (formKey && formKey != '') {
                    if (this.moduleCode == 'BF001') {
                        _this.axios.post(this.Global.config.apiBaseURL + _this.Global.api.v2.customerWithContact_add, {
                            'customer': data1,
                            'custContact': data2,
                            formKey: formKey
                        }).then(function (res) {
                            _this.$toast.clear()
                            if (res.data.code.toString() == _this.Global.config.RES_OK) {
                                _this.$toast.success(res.data.msg)
                                _this.set_closeYes(false)
                                _this.$router.back()
                            } else {
                                _this.$toast.fail(res.data.msg)
                            }
                        }, function (res) {
                            _this.$toast.clear()
                            _this.$toast.fail(_this.Global.config.errorTitle)
                        })
                    } else {
                        data1['moduleCode'] = _this.moduleCode
                        data1.approvalType = '1'
                        data1.formKey = formKey
                        _this.axios.post(this.Global.config.apiBaseURL + _this.Global.api.v2.document_generalOperate_add, data1).then(function (res) {
                            _this.$toast.clear()
                            if (res.data.code.toString() == _this.Global.config.RES_OK) {
                                _this.$toast.success(res.data.msg)
                                _this.set_closeYes(false)
                                _this.$router.back()
                            } else {
                                _this.$toast.fail(res.data.msg)
                            }
                        }, function (res) {
                            _this.$toast.clear()
                            _this.$toast.fail(_this.Global.config.errorTitle)
                        })
                    }
                } else {
                    _this.$toast.clear()
                    _this.$toast.fail(msg)
                }
            })
        },
        checkBe(obj) {
            if (!(typeof obj == 'object')) {
                return
            }
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && (obj[key] == null || obj[key] == undefined || obj[key] == '')) {
                    delete obj[key]
                }
            }
            return obj
        }
    },
    watch: {
    },
    components: {
        'control-vue': controlVue,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
