<template>
    <div class="add" style="bottom:0;">
        <ot-new ref="otNew" v-if="moduleCode!=''" :moduleCode="moduleCode" :sysBoxValue="sysBoxValue" :AONIcustSort="AONIcustSort" :currency="currency" :cusBoxValue="cusBoxValue"></ot-new>
    </div>
</template>
<script>
import otNew from '@/page/Client/CustPublic/optVue/otNew/index'
import { mapGetters, mapMutations } from 'vuex'
import { getIsApp } from '@/libs/utils'
export default {
    name: 'add',
    title: '新增', // 新增
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            msg: 'add',
            pageUrl: '',
            moduleCode: ''
        }
    },
    created() {
        this.moduleCode = this.$route.params.moduleCode
    },
    mounted() {
        this.setMenu()
    },
    methods: {
        ...mapMutations({
            set_closeYes: 'SET_CLOSEYES'
        }),
        setMenu() {
            if (this.moduleCode == 'BF001') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585300521873')) // 新增客户
            } else if (this.moduleCode == 'BF003') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585300691680')) // 新增联系人
            } else if (this.moduleCode == 'BF004') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585300703687')) // 新增跟进
            } else {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585272488531'))// 新增
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else if (this.closeYes) {
            if (!getIsApp()) {
                this.$dialog.confirm({
                    title: this.$t('mx_mobile.Client.1585304004347'), // 提示
                    message: this.$t('mx_mobile.Client.1585304000139'), // '已编辑内容将不会保存，是否确定离开？'
                    closeOnPopstate: false
                }).then((res) => {
                    next()
                }).catch(() => {
                    next(false)
                })
            } else {
                next()
            }
        } else {
            this.set_closeYes(true)
            next()
        }
    },
    computed: {
        ...mapGetters(['closeYes'])
    },
    watch: {
        '$route': function (val, old) {
            if (val.path.indexOf('/client/add') != -1) {
                this.moduleCode = val.params.moduleCode
                this.setMenu()
                setTimeout(() => {
                    this.$refs.otNew.update()
                }, 20)
            }
        }
    },
    components: {
        'ot-new': otNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
